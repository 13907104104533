import React from 'react';
import { PageProps } from 'gatsby';

import Layout from '~/components/Layout';
import ProjectHeader from '~/components/ProjectHeader';
import robnPage from '~/data/case-studies/robn';

import styles from './Robn.module.scss';
import ScopeDescription from '~/components/ScopeDescription';
import ProjectExcerpt from '~/components/ProjectExcerpt';
import ProjectPlatform from '~/components/ProjectPlatform';
import EstimationCTA from '~/components/EstimationCTA';
import Advice from '~/components/Advice';
import Button from '~/components/Button';
import { ReactComponent as MediumIcon } from '~/assets/icons-social-media/medium.svg';
import ClientDescription from '~/components/ClientDescription';
import YoutubeVideo from '~/components/YoutubeVideo';
import OtherProjects from '../components/OtherProjects';

const Robn: React.FC<PageProps> = ({ location }) => {
  const {
    introSection,
    clientSection,
    workSection,
    tldrSection,
    platformsSection,
    appSection,
    videoSection,
    mediumSection,
  } = robnPage;

  return (
    <Layout invertNavigation>
      <header className={styles.header}>
        <ProjectHeader data={introSection} />
      </header>
      <section className={styles.client}>
        <ClientDescription data={clientSection} />
      </section>
      <section className={styles.platforms}>
        <ProjectPlatform data={platformsSection} />
      </section>
      <section className={styles.tldr}>
        <ProjectExcerpt data={tldrSection} />
      </section>
      <section className={styles.work}>
        <ScopeDescription data={workSection} />
      </section>
      <section className={styles.platforms}>
        <ProjectPlatform data={appSection} inOneLine />
      </section>
      <section className={styles.advice}>
        <Advice data={mediumSection}>
          <Button
            isTransparent
            icon={<MediumIcon />}
            as={{
              tag: 'a',
              href: mediumSection.url,
              target: '_blank',
              rel: 'noopener noreferrer',
            }}
            className={styles.medium}
            iconClass={styles.mediumIcon}
          >
            Read More
          </Button>
        </Advice>
      </section>
      <section className={styles.video}>
        <YoutubeVideo data={videoSection} />
      </section>
      <section className={styles.estimation}>
        <EstimationCTA />
      </section>
      <article>
        <OtherProjects curretPageUrl={location.pathname} />
      </article>
    </Layout>
  );
};

export default Robn;
