import { MediumSection } from '~/pages/case-studies/robn/types';

const mediumSection: MediumSection = {
  text:
    'To create a good app that integrates with a device, you need to follow these rules…',
  url:
    'https://medium.com/swinginc/4-things-to-remember-when-working-on-a-device-companion-app-6931067042e1',
};

export default mediumSection;
