import robnApp4 from './assets/robn-app-4.jpg';
import robnApp5 from './assets/robn-app-5.jpg';
import robnApp6 from './assets/robn-app-6.jpg';
import { ProjectPlatformData } from '../../../../components/ProjectPlatform';

const appSection: ProjectPlatformData = {
  projectName: 'Robn',
  slides: [robnApp4, robnApp5, robnApp6],
};

export default appSection;
