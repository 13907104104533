import { ProjectDTO } from '~/types';
import { ProjectCategory } from '~/enums';
import heroImage from './assets/robn.jpg';
import heroImageMobile from './assets/robn-mobile.jpg';

const introSection: ProjectDTO = {
  category: ProjectCategory.SPORTS,
  name: 'Robn',
  summary: 'Every Sports’ Fan Outdoor Companion',
  img: heroImage,
  imgMobile: heroImageMobile,
  description: '',
  projectPageUrl: '',
  appStoreUrl:
    'https://apps.apple.com/us/app/robn-audio-comms-on-the-go/id1480232572?ls=1',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=io.swingdev.hrtapp',
};

export default introSection;
