import React from 'react';

import { YOUTUBE_VIDEO_ALLOW_OPTIONS } from '~/constants';

import styles from './YoutubeVideo.module.scss';

export type YoutubeVideoData = {
  embedUrl: string;
  title: string;
};

type Props = {
  data: YoutubeVideoData;
};

const YoutubeVideo: React.FC<Props> = ({ data }) => {
  const { embedUrl, title } = data;

  return (
    <div className={styles.root}>
      <iframe
        title={title}
        src={embedUrl}
        frameBorder='0'
        className={styles.video}
        allow={YOUTUBE_VIDEO_ALLOW_OPTIONS.join(',')}
        allowFullScreen
      />
    </div>
  );
};

export default YoutubeVideo;
