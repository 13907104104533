import { RobnPageDTO } from '~/pages/case-studies/robn/types';

import introSection from './introSection';
import clientSection from './clientSection';
import workSection from './workSection';
import tldrSection from './tldrSection';
import platformsSection from './platformsSection';
import appSection from './appSection';
import videoSection from './videoSection';
import mediumSection from './mediumSection';

const robnPage: RobnPageDTO = {
  introSection,
  clientSection,
  tldrSection,
  platformsSection,
  workSection,
  appSection,
  videoSection,
  mediumSection,
};

export default robnPage;
