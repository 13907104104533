import { ClientDescriptionData } from '~/components/ClientDescription';

const clientSection: ClientDescriptionData = {
  title: 'The Client',
  textShort:
    'HrtBeat Audio is a wearable hardware and mobile software company that creates products for people who live actively.',
  textLong:
    'Their devices enrich training experience by providing such features as wireless audio, walkie-talkie and voice assistants. Thanks to hands-free control and built-in speakers users can engage with the world and stay safe & aware during their outdoor sessions. HrtBeat’s first project is a smart harness called ROBN.',
  scope: ['Design', 'Development'],
  techStack: ['Native iOS (Swift) with BLE integration'],
};

export default clientSection;
