import robnApp1 from './assets/robn-app-1.jpg';
import robnApp2 from './assets/robn-app-2.jpg';
import robnApp3 from './assets/robn-app-3.jpg';
import { ProjectPlatformData } from '~/components/ProjectPlatform';

const platformsSection: ProjectPlatformData = {
  projectName: 'Robn',
  platforms: ['iOS', 'Android'],
  slides: [robnApp1, robnApp2, robnApp3],
  appStoreUrl:
    'https://apps.apple.com/us/app/robn-audio-comms-on-the-go/id1480232572?ls=1',
  googlePlayUrl:
    'https://play.google.com/store/apps/details?id=io.swingdev.hrtapp',
};

export default platformsSection;
