import React from 'react';

import { ReactComponent as FlourishDoodle } from '~/assets/icons/doodle-flourish.svg';

import styles from './Advice.module.scss';

export type AdviceData = {
  text: string;
};

type Props = {
  data: AdviceData;
};

const Advice: React.FC<Props> = ({ data, children }) => {
  const { text } = data;

  return (
    <div className={styles.root}>
      <p className={styles.quote}>{text}</p>
      <div className={styles.content}>
        <FlourishDoodle className={styles.doodle} />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Advice;
